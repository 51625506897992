import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import theme from "./theme"

export const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  font-size: ${theme.fontsizes.smallTextMediumScreen};
  color: ${theme.colors.darkFont};

  :hover {
    font-weight: bold;
  }

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.smallTextSmallScreen};
  }
`

export const Body = styled.div`
  background-color: ${theme.colors.bodyBackground};
  padding: ${theme.spaces.standardPadding};
  color: ${theme.colors.darkFont};
  font-family: ${theme.fontFamily};
`
