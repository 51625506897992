import React from "react"
import { graphql } from "gatsby"
import theme from "../shared/theme"
import styled from "@emotion/styled"
import * as BlockContent from "@sanity/block-content-to-react"
import Image from "gatsby-image"
import Button from "../elements/button"
import { StyledLink } from "../shared/styledElements"
import SEO from "../components/seo.js"
import BlockRenderer from "../utils/textRenderer.js"
import Layout from "../shared/layout.js"

export const query = graphql`
  query($slug: String) {
    sanityBookreview(slug: { current: { eq: $slug } }) {
      booktitle
      artist
      credits
      slug {
        current
      }
      subheadline
      _rawReviewText
      titleImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      bookImages {
        image_title
        alt_text
        image_bookReview {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const localDescription = `Buchrezension zu ${data.sanityBookreview.booktitle} von ${data.sanityBookreview.artist}. Stilanalysen und fotografische Inspirationen für Fotografen.`

  return (
    <Layout>
      <SEO
        title={
          data
            ? `Buchrezension ${data.sanityBookreview.artist} ${data.sanityBookreview.booktitle}`
            : ""
        }
        description={localDescription}
      />
      <Body>
        <Headline>
          <FirstRow>
            <span>
              {data.sanityBookreview.artist ? data.sanityBookreview.artist : ""}
            </span>{" "}
            <StyledLink to="/bookreview_overview">Zur Übersicht</StyledLink>
          </FirstRow>
          <h1>
            {data.sanityBookreview.booktitle
              ? data.sanityBookreview.booktitle
              : ""}
          </h1>
        </Headline>

        <Content>
          {/* linke Spalte */}
          <Images>
            {data.sanityBookreview.titleImage ? (
              <TitleImage>
                <Image
                  fluid={
                    data.sanityBookreview.titleImage.asset
                      ? data.sanityBookreview.titleImage.asset.fluid
                      : null
                  }
                  alt={
                    data.sanityBookreview.artist +
                    data.sanityBookreview.booktitle
                  }
                  title={data.sanityBookreview.booktitle}
                />
              </TitleImage>
            ) : null}
            <PicturesHeadline>
              <p>Bilder</p>
            </PicturesHeadline>
            {data.sanityBookreview.bookImages.map((image, index) => {
              return (
                <div key={index}>
                  <Image
                    fluid={image && image.image_bookReview.asset.fluid}
                    alt={image.alt_text}
                    title={image.image_title}
                  />
                  <div>{image && image.image_title}</div>
                </div>
              )
            })}
          </Images>

          {/* Textspalte */}
          <ProjectText>
            <div>
              <BlockContent
                blocks={
                  data.sanityBookreview._rawReviewText
                    ? data.sanityBookreview._rawReviewText
                    : ""
                }
                serializers={{ types: { block: BlockRenderer } }}
              />
            </div>
            <Credits>{data.sanityBookreview.credits}</Credits>
          </ProjectText>
        </Content>
        <Button
          ariaLabel="zur Übersichtsseite der Buchrezensionen zum Thema Fotografie"
          linkTo="/bookreview_overview/"
        >
          Zur Übersicht
        </Button>
      </Body>
    </Layout>
  )
}

const Body = styled.div`
  background-color: ${theme.colors.bodyBackground};
  padding: ${theme.spaces.standardPadding};
  color: ${theme.colors.darkFont};
`

const Headline = styled.div`
  font-family: ${theme.fontFamilyHeading};
  text-transform: uppercase;
  padding-bottom: ${theme.spaces.standardPadding};

  h1 {
    font-size: calc(2 * ${theme.fontsizes.h1MediumScreen});
  }

  span {
    font-size: ${theme.fontsizes.subBannerMediumScreen};
    font-weight: lighter;
  }

  @media all and (max-width: 768px) {
    h1 {
      font-size: calc(3 * ${theme.fontsizes.h1MediumScreen});
    }

    span {
      font-size: calc(2 * ${theme.fontsizes.subBannerMediumScreen});
    }
  }
`

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TitleImage = styled.div`
  margin-bottom: calc(2 * ${theme.spaces.standardPadding});
`

const PicturesHeadline = styled.div`
  font-size: ${theme.fontsizes.textMediumScreen};
  font-weight: bolder;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: ${theme.spaces.standardPadding};
`

const Images = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% / 3 * 1 - 1vw);
  margin-right: ${theme.spaces.standardPadding};

  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.smallTextMediumScreen};
  line-height: ${theme.spaces.textMediumScreenLineHeight};

  @media all and (max-width: 768px) {
    margin-right: ${theme.spaces.smallSpacing};
  }
`

const ProjectText = styled.div`
  width: calc(100% / 3 * 2);
  border-left: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};

  div {
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontsizes.textMediumScreen};
    line-height: ${theme.spaces.textMediumScreenLineHeight};
    text-align: justify;
    padding-left: 1vw;
  }

  @media all and (max-width: 768px) {
    div {
      font-size: ${theme.fontsizes.textSmallScreen};
      line-height: ${theme.spaces.textSmallScreenLineHeight};
    }
  }
`

const Credits = styled.div`
  margin: ${theme.spaces.standardPadding};
  padding: ${theme.spaces.buttonPaddingSideBigScreen};
  border: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
  border-color: ${theme.colors.darkFont};
  font-size: ${theme.fontsizes.smallTextMediumScreen} !important;
  text-align: center !important;
`
